import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AboutSvg,
  AndroidSVG,
  DownloadSvg,
  FeaturesSvg,
  HouseSvg,
  IosSVG,
  ListSVG,
  MapPinSvg,
  QuestionSvg,
  SearchSVG,
  TestimonialSvg,
} from "../../assets/svg";
import { COLORS } from "../../assets/Theme";
import { DownloadHoverItem } from "./Navbar";

const SideNav = ({ isOpen, handleCloseNav }) => {
  const [showCard, setShowCard] = useState(false);
  const [state, setState] = useState("");
  const navigate = useNavigate();

  const handleTrack = () => {
    // check if tracking number added
    if (!state || state === "") {
      window.alert("Tracking number required");
      return;
    }

    const trackingNumber = state?.split("-");
    //check tracking format
    if (trackingNumber?.length < 4) {
      window.alert(
        "Invalid tracking number. Please enter the correct tracking number"
      );
      return;
    }
    navigate(`/track-package?${state}`);
  };

  return (
    <div
      className={`z-[200] top-0 fixed right-0 bottom-0 h-screen bg-[#00000050]  ${
        isOpen ? " w-screen " : "w-0"
      } transition-all duration-300 ease-in-out overflow-hidden`}
      //   onClick={handleCloseNav}
    >
      <div
        className={`float-right h-full bg-primary z-50 ${
          isOpen ? " w-screen lg:w-[30vw] md:w-[50vw] sm:w-[70vw]" : "w-0"
        } transition-all duration-700 ease-in-out overflow-hidden`}
        onClick={() => {}}
      >
        <div className={`block`}>
          <div className="p-5 flex justify-end">
            <button className="" onClick={handleCloseNav}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.02136 28.9813L15.0027 15M28.984 1.01868L15 15M15 15L1.02136 1.01868M15.0027 15L28.984 28.9813"
                  stroke="#000"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>

          <div className=" flex flex-col justify-center gap-4 py-6">
            {nav_list_items?.map((item, i) => (
              <a href={item?.link} key={i} onClick={handleCloseNav}>
                <div
                  className={
                    "w-1/2 mx-auto flex gap-x-3 p-2 items-center hover:scale-110"
                  }
                >
                  {item?.icon}
                  <div
                    className={`text-black font-lato font-[500]   duration-700 flex gap-x-3 ${
                      isOpen ? "text-base" : "text-[0px]"
                    } `}
                  >
                    {item?.name}
                  </div>
                </div>
              </a>
            ))}

            <button
              className={"w-1/2 mx-auto flex gap-x-3 p-2 items-center relative"}
              onClick={() => setShowCard(!showCard)}
            >
              <DownloadSvg />
              <div
                className={`text-black font-lato font-[500] duration-700 flex gap-x-3 ${
                  isOpen ? "text-base" : "text-[0px]"
                } `}
              >
                Download
              </div>

              {showCard && (
                <div
                  className={`rounded-lg absolute left-0 right-12 -top-0 z-10 cursor-pointer `}
                >
                  <div className="h-10 w-20" />
                  <div className={`bg-none rounded-lg overflow-hidden  `}>
                    <DownloadHoverItem
                      className={"border-none bg-none hover:bg-none"}
                      icon={<AndroidSVG />}
                      text={"Android"}
                      link={
                        "https://play.google.com/store/apps/details?id=com.pum_vendor"
                      }
                    />
                    <DownloadHoverItem
                      className={"border-none bg-none"}
                      link={
                        "https://apps.apple.com/ke/app/pickup-mtaani/id6448919500"
                      }
                      icon={<IosSVG />}
                      text={"iPhone"}
                    />
                  </div>
                </div>
              )}
            </button>
          </div>

          <div
            className={
              "bg-none border border-[#272D2D] h-[42px] flex items-center pl-2 rounded-xl overflow-hidden mt-16 w-10/12 mx-auto"
            }
          >
            {/*  icon  */}
            <div className={"flex-shrink-0"}>
              <MapPinSvg color={"#272D2D"} />
            </div>

            <div className={"h-4 rounded-full bg-[#000] w-[1px] mx-2"} />

            <input
              className={
                "flex-1 h-full bg-transparent font-lato font-[500] text-sm focus:outline-none text-white_bg placeholder-grey_800"
              }
              placeholder={"Track package by code"}
              value={state}
              onChange={(e) => setState(e?.target?.value)}
            />

            <button
              className={
                "gap-2 flex items-center justify-center bg-grey_900 text-primary_300 px-3 h-full font-[600]"
              }
              onClick={handleTrack}
            >
              <SearchSVG color={COLORS.primary} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNav;

// nav list items
const nav_list_items = [
  {
    name: "Home",
    link: "/",
    icon: <HouseSvg />,
  },
  {
    name: "Features",
    link: "/#features",
    icon: <FeaturesSvg />,
  },
  {
    name: "Testimonials",
    link: "/#testimonials",
    icon: <TestimonialSvg />,
  },
  {
    name: "About Us",
    link: "/#about",
    icon: <AboutSvg />,
  },
  {
    name: "Questions",
    link: "/#faq",
    icon: <QuestionSvg />,
  },
  {
    name: "Agents",
    link: "/agents",
    icon: <ListSVG />,
  },

];
