import { persist } from "zustand/middleware";
import { create } from "zustand";

const initState = {
  bucketUrl: "https://images.pickupmtaani.com/",
};

const bucketStorage = create(
  //   save the state to local storage
  persist(
    (set) => ({
      state: initState,

      //     methods
      setBucketUrl: (bucketUrl) =>
        set((previousState) => ({
          state: { ...previousState?.state, bucketUrl },
        })),

      resetState: () =>
        set(() => ({
          state: initState,
        })),
    }),

    // local storage configuration:
    {
      name: "_state_bucket", // name of the item in the storage (must be unique)
    }
  )
);

export const useBucket = () => {
  const bucketUrl = bucketStorage((state) => state.state.bucketUrl);

  const setBucketUrl = bucketStorage((state) => state.setBucketUrl);

  return {
    bucketUrl,
    setBucketUrl,
  };
};

export default bucketStorage;
