import React from "react";
import StarSvg from "../../../assets/svg/StarSvg";
import VerifiedSvg from "../../../assets/svg/VerifiedSvg";
import FeedbackDialog from "../../feedback/FeedbackDialog";
import { PhoneSvg } from "../../../assets/svg";

const RiderDetails = ({ riderName, riderPhone }) => {
  const riderImg = require("../../../assets/rider-avatar.png");

  return (
    <div className="p-4 rounded-2xl bg-white border border-gray-200 w-full">
      <div className={"flex gap-x-3 items-center"}>
        <div className={"flex-shrink-0"}>
          <img className={""} src={riderImg} alt={"avt"} />
        </div>
        <div className={"flex-1"}>
          <div className={"flex gap-x-3 items-center"}>
            <h5 className={"font-bold text-base"}>{riderName ?? ""}</h5>
            <VerifiedSvg />
          </div>

          <div className={"flex space-x-2 items-center"}>
            {/*  star  */}
            <StarSvg />

            <span className={"text-gray-700 font-medium text-base"}>4.5</span>
            <span className={"font-light text-gray-500 text-sm"}>
              (490 trips)
            </span>
          </div>
        </div>
      </div>
      <FeedbackDialog>
        <button
          className={`font-semibold text-sm p-2.5 w-full hover:bg-gray-100 rounded-lg flex items-center justify-between`}
        >
          Contact Us
          <PhoneSvg />
        </button>
      </FeedbackDialog>
    </div>
  );
};

export default RiderDetails;
